import React from 'react'
import { Link } from 'react-scroll'

const Footer = () => {
  return (
    <div>
      <footer className="py-4 px-2 flex items-center justify-center gap-4">
        <p className="text-sm">
          &copy; 2023 Aashish Kumar | All Rights Reserved.
        </p>
        <Link
          to="home"
          spy={true}
          offset={-50}
          smooth={true}
          duration={500}
          className="bg-[#292e33] flex items-center px-2 py-1 rounded-md cursor-pointer"
        >
          <i className="bx bx-up-arrow-alt text-xl"></i>
        </Link>
      </footer>
    </div>
  )
}

export default Footer
