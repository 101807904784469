import React from "react";
import { Link } from "react-scroll";

const Header = () => {
  return (
    <div>
      <header className="fixed z-20 flex items-center justify-between w-full bg-[#1e1e1e] px-4 md:px-[10%] py-3 border-b border-transparent left-0 top-0">
        <Link
          to="home"
          spy={true}
          offset={-50}
          smooth={true}
          duration={500}
          className="text-[26px] font-bold tracking-[1px] cursor-pointer"
        >
          <span className="text-[#12f7ff]">A</span>ashish.
        </Link>
        <nav>
          <ul className="flex gap-4 md:gap-12 text-sm">
            <li>
              <Link
                to="home"
                spy={true}
                offset={-50}
                smooth={true}
                duration={500}
                className="text-[#f5f5f5] font-medium hover:text-[#12f7ff] transition-all cursor-pointer"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="about"
                spy={true}
                offset={-50}
                smooth={true}
                duration={500}
                className="text-[#f5f5f5] font-medium hover:text-[#12f7ff] transition-all cursor-pointer"
              >
                About
              </Link>
            </li>
            <li>
              <Link
                to="projects"
                spy={true}
                offset={-50}
                smooth={true}
                duration={500}
                className="text-[#f5f5f5] font-medium hover:text-[#12f7ff] transition-all cursor-pointer"
              >
                Projects
              </Link>
            </li>
            <li>
              <Link
                to="skills"
                spy={true}
                offset={-50}
                smooth={true}
                duration={500}
                className="text-[#f5f5f5] font-medium hover:text-[#12f7ff] transition-all cursor-pointer"
              >
                Skills
              </Link>
            </li>
          </ul>
        </nav>
        <div
          id="menu-icon"
          className="text-[1.8rem] z-[1001] cursor-pointer text-[#292e33] hidden ml-[25px] rounded-[3px] bg-[#12f7ff]"
        >
          &#9776;
        </div>
      </header>
    </div>
  );
};

export default Header;
