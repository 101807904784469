import React from "react";

const About = () => {
  return (
    <div>
      <section
        id="about"
        className="w-full flex flex-col lg:flex-row items-center justify-between gap-8 bg-[#292e33] px-4 lg:px-[10%] py-8 lg:py-32"
      >
        <div className="flex-shrink-0">
          <img
            src="photo-8.png"
            alt="photo2"
            width={400}
            height={400}
            className="drop-shadow-[0_4px_6px_rgba(18,247,255,5)]"
          />
        </div>
        <div className="max-w-2xl text-justify text-gray-200">
          <span className="block uppercase tracking-wider font-light text-[#12f7ff] text-sm mb-2">
            let me introduce myself
          </span>
          <h2 className="text-3xl font-bold mb-4">About Me</h2>
          <p className="mb-4 font-light">
            I am a recent graduate in Computer Engineering from Institute of
            Engineering (IOE), Pulchowk Campus. I have always been fascinated by
            how software can shape our lives and improve the way we interact
            with the world. Although I'm relatively new to the field, I have a
            strong foundation in computer engineering and a deep enthusiasm for
            learning.
          </p>
          <p className="mb-4 font-light">
            My journey began with a curiosity-driven exploration of coding, and
            since then, I have been captivated by the endless possibilities it
            offers. I am constantly expanding my knowledge and skills, eagerly
            embracing new technologies and frameworks along the way. As a
            beginner, I understand the importance of continuous growth and
            hands-on experience. I have actively sought out opportunities to
            work on real-world projects, both independently and collaboratively.
            These experiences have allowed me to apply the theoretical knowledge
            I gained in my studies and gain valuable insights into the practical
            aspects of software development.
          </p>
          <p className="font-light">
            Thank you for visiting my portfolio website. If you have any
            inquiries, collaboration opportunities, or if you simply want to
            connect and share experiences, please feel free to reach out. I am
            eager to explore new opportunities, expand my network, and
            contribute to meaningful projects. Let's embark on this exciting
            journey together!
          </p>
        </div>
      </section>
    </div>
  );
};

export default About;
