import React from "react";
import { Link } from "react-router-dom";

const Projects = () => {
  return (
    <div>
      <section id="projects" className="px-4 md:px-[10%] py-8 md:py-16">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold relative inline-block">Projects</h2>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Project 1 */}
          <div className="relative bg-[#292e33] rounded-xl overflow-hidden shadow-lg transition-shadow duration-300 group">
            <img
              src="MegaMart.jpg"
              alt="MegaMart"
              className="w-full h-96 object-contain"
            />
            <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-60 transition duration-300 flex items-center justify-center">
              <div className="opacity-0 group-hover:opacity-100 transition duration-300 text-center p-6">
                <h3 className="text-xl font-semibold text-white mb-2">
                  Mega Mart
                </h3>
                <p className="text-gray-200 mb-4">
                  A fully-featured Multi-Vendor E-commerce platform built using
                  the MERN stack (MongoDB, Express, React, Node.js) with
                  Tailwind CSS for styling. It offers a seamless shopping
                  experience with integrated Stripe payment processing.
                </p>
                <Link
                  to={"https://mega-mart-1.onrender.com"}
                  target="_blank"
                  className="text-black bg-[#12f7ff] px-3 py-2.5 rounded-full hover:underline"
                >
                  <i className="bx bx-link-external"></i>
                </Link>
              </div>
            </div>
          </div>

          {/* Project 2 */}
          <div className="relative bg-[#292e33] rounded-xl overflow-hidden shadow-lg transition-shadow duration-300 group">
            <img
              src="GrievanceRecognition.png"
              alt="GrievanceRecognition"
              className="w-full h-96 object-contain"
            />
            <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-60 transition duration-300 flex items-center justify-center">
              <div className="opacity-0 group-hover:opacity-100 transition duration-300 text-center p-6">
                <h3 className="text-xl font-semibold text-white mb-2">
                  Grievance Recognition System
                </h3>
                <p className="text-gray-200 mb-4">
                  An application designed to automate the handling of grievances
                  received in Nepali text or speech formats using advanced ML
                  and NLP techniques.
                </p>
                <Link
                  to={"https://github.com/Aashish12321/GrievanceRecognition"}
                  target="_blank"
                  className="text-black bg-[#12f7ff] px-3 py-2.5 rounded-full hover:underline"
                >
                  <i className="bx bx-link-external"></i>
                </Link>
              </div>
            </div>
          </div>

          {/* Project 3 */}
          <div className="relative bg-[#292e33] rounded-xl overflow-hidden shadow-lg transition-shadow duration-300 group">
            <img
              src="NovelNavigator.jpg"
              alt="Novel Navigator"
              className="w-full h-96 object-contain"
            />
            <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-60 transition duration-300 flex items-center justify-center">
              <div className="opacity-0 group-hover:opacity-100 transition duration-300 text-center p-6">
                <h3 className="text-xl font-semibold text-white mb-2">
                  Novel Navigator
                </h3>
                <p className="text-gray-300 mb-4">
                  A Personalized Book Recommendation System developed using
                  React for the frontend, Flask for the backend, SQLite for data
                  management, and Material UI for an intuitive and responsive
                  user interface.
                </p>
                <Link
                  to={
                    "https://github.com/Aashish12321/Novel-Navigator-Frontend"
                  }
                  target="_blank"
                  className="text-black bg-[#12f7ff] px-3 py-2.5 rounded-full hover:underline"
                >
                  <i className="bx bx-link-external"></i>
                </Link>
              </div>
            </div>
          </div>

          {/* Project 4 */}
          <div className="relative bg-[#292e33] rounded-xl overflow-hidden shadow-lg transition-shadow duration-300 group">
            <img
              src="IntelligentSnake.jpg"
              alt="Intelligent Snake"
              className="w-full h-96 object-contain"
            />
            <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-60 transition duration-300 flex items-center justify-center">
              <div className="opacity-0 group-hover:opacity-100 transition duration-300 text-center p-6">
                <h3 className="text-xl font-semibold text-white mb-2">
                  Intelligent Snake
                </h3>
                <p className="text-gray-300 mb-4">
                  A Snake game created in Python using Pygame library controlled
                  by AI using the A* algorithm. It can also be played manually.
                </p>
                <Link
                  to={
                    "https://github.com/Aashish12321/Intelligent-Snake-Implementing-A-star-algorithm"
                  }
                  target="_blank"
                  className="text-black bg-[#12f7ff] px-3 py-2.5 rounded-full hover:underline"
                >
                  <i className="bx bx-link-external"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Projects;
