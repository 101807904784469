import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

const Home = () => {
  useEffect(() => {
    let words = document.querySelectorAll(".word");
    words.forEach((word) => {
      let letters = word.textContent.split("");
      word.textContent = "";
      letters.forEach((letter) => {
        let span = document.createElement("span");
        span.textContent = letter;
        span.className = "letter";
        word.append(span);
      });
    });

    let currentWordIndex = 0;
    let maxWordIndex = words.length - 1;
    words[currentWordIndex].style.opacity = "1";

    let changeText = () => {
      let currentWord = words[currentWordIndex];
      let nextWord =
        currentWordIndex === maxWordIndex
          ? words[0]
          : words[currentWordIndex + 1];

      Array.from(currentWord.children).forEach((letter, i) => {
        setTimeout(() => {
          letter.className = "letter out";
        }, i * 80);
      });

      nextWord.style.opacity = "1";
      Array.from(nextWord.children).forEach((letter, i) => {
        letter.className = "letter behind";
        setTimeout(() => {
          letter.className = "letter in";
        }, 340 + i * 80);
      });

      currentWordIndex =
        currentWordIndex === maxWordIndex ? 0 : currentWordIndex + 1;
    };

    changeText();
    setInterval(changeText, 3000);
  });
  return (
    <div>
      <section
        id="home"
        className="flex flex-col lg:flex-row text-left items-center justify-between px-4 lg:px-[10%] py-52 lg:py-32"
      >
        <div className="w-full max-w-xl">
          <h1 className="text-3xl md:text-4xl font-bold mb-4">
            Hello, I'm Aashish Kumar
          </h1>
          <div className="change-text text-2xl font-semibold mb-2">
            <h3 className="inline-flex align-top m-0">And I'm a</h3>
            <h3 className="inline-flex align-top m-0">
              <span className="word text-[#12f7ff]">
                &nbsp;Computer&nbsp;Engineer
              </span>
              <span className="word text-[#12f7ff]">
                &nbsp;Software&nbsp;Developer
              </span>
            </h3>
          </div>

          <p className="mb-4">
            Welcome to my Portfolio Website. I'm Aashish Kumar Sah, a passionate
            Computer Engineer and Software Developer who is embarking on an
            exciting journey in the world of technology.
          </p>
          <div className="btn-box mb-4">
            <ScrollLink
              to="about"
              spy={true}
              offset={-50}
              smooth={true}
              duration={500}
              className="btn text-[#12f7ff] bg-[#292e33] px-4 py-2 rounded hover:bg-[#12f7ff] hover:text-[#1e1e1e] transition cursor-pointer"
            >
              Read More
            </ScrollLink>
          </div>
          <div className="info-box mb-4">
            <div className="email-info">
              <h5 className="font-bold">Email:</h5>
              <span>shroffaashish@gmail.com</span>
            </div>
          </div>
          <div className="btn-box mb-6">
            <Link
              to="Aashish-Sah-CV.pdf"
              target="_blank"
              className="btn text-[#12f7ff] bg-[#292e33] px-4 py-2 rounded hover:bg-[#12f7ff] hover:text-[#1e1e1e] transition"
            >
              Download CV
            </Link>
          </div>
          <div className="social-icons flex gap-4">
            <Link
              to="https://www.facebook.com/aashish.shroff.9"
              target="_blank"
              className="text-[#12f7ff] hover:text-[#f5f5f5]"
            >
              <i className="bx bxl-facebook text-2xl"></i>
            </Link>
            <Link
              to="https://www.instagram.com/shroffaashish"
              target="_blank"
              className="text-[#12f7ff] hover:text-[#f5f5f5]"
            >
              <i className="bx bxl-instagram text-2xl"></i>
            </Link>
            <Link
              to="https://www.linkedin.com/in/aashish-kumar-sah-650962230"
              target="_blank"
              className="text-[#12f7ff] hover:text-[#f5f5f5]"
            >
              <i className="bx bxl-linkedin text-2xl"></i>
            </Link>
            <Link
              to="https://github.com/Aashish12321"
              target="_blank"
              className="text-[#12f7ff] hover:text-[#f5f5f5]"
            >
              <i className="bx bxl-github text-2xl"></i>
            </Link>
          </div>
        </div>
        <div className="hidden lg:flex">
          <img src="photo-7.png" alt="photo1" width={400} height={400} />
        </div>
      </section>
    </div>
  );
};

export default Home;
