import React from "react";

const Skills = () => {
  return (
    <div>
      <section
        id="skills"
        className="bg-[#292e33] px-4 md:px-[10%] py-12"
      >
        <div className="main-text text-center mb-8">
          <h2 className="text-3xl font-bold">Skills</h2>
        </div>

        <div className="grid grid-cols-3 gap-8 justify-center">
          <img
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg"
            alt="HTML5 logo"
            title="HTML5"
            className="w-16 h-16 mx-auto p-2 hover:drop-shadow-[0_4px_6px_rgba(18,247,255,5)]"
          />
          <img
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original-wordmark.svg"
            alt="CSS3 logo"
            title="CSS 3"
            className="w-16 h-16 mx-auto p-2 hover:drop-shadow-[0_4px_6px_rgba(18,247,255,5)]"
          />
          <img
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-plain.svg"
            alt="JavaScript logo"
            title="JavaScript"
            className="w-16 h-16 mx-auto p-2 hover:drop-shadow-[0_4px_6px_rgba(18,247,255,5)]"
          />
          <img
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original-wordmark.svg"
            alt="React logo"
            title="React"
            className="w-16 h-16 mx-auto p-2 hover:drop-shadow-[0_4px_6px_rgba(18,247,255,5)]"
          />
          <img
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-plain-wordmark.svg"
            alt="Node.js logo"
            title="Node.js"
            className="w-16 h-16 mx-auto p-2 hover:drop-shadow-[0_4px_6px_rgba(18,247,255,5)]"
          />
          <img
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mongodb/mongodb-original-wordmark.svg"
            alt="MongoDB logo"
            title="MongoDB"
            className="w-16 h-16 mx-auto p-2 hover:drop-shadow-[0_4px_6px_rgba(18,247,255,5)]"
          />

          <img
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bootstrap/bootstrap-original-wordmark.svg"
            alt="Bootstrap logo"
            title="Bootstrap"
            className="w-16 h-16 mx-auto p-2 hover:drop-shadow-[0_4px_6px_rgba(18,247,255,5)]"
          />
          <img
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/tailwindcss/tailwindcss-original-wordmark.svg"
            alt="TailwindCSS logo"
            title="Tailwind CSS"
            className="w-20 h-20 mx-auto p-2 hover:drop-shadow-[0_4px_6px_rgba(18,247,255,5)]"
          />
          <img
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nextjs/nextjs-original-wordmark.svg"
            alt="NextJS logo"
            title="NextJS"
            className="w-16 h-16 mx-auto p-2 hover:drop-shadow-[0_4px_6px_rgba(18,247,255,5)]"
          />
          <img
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-plain-wordmark.svg"
            alt="Python logo"
            title="Python"
            className="w-16 h-16 mx-auto p-2 hover:drop-shadow-[0_4px_6px_rgba(18,247,255,5)]"
          />
          <img
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/flask/flask-original-wordmark.svg"
            alt="Flask logo"
            title="Flask"
            className="w-16 h-16 mx-auto p-2 hover:drop-shadow-[0_4px_6px_rgba(18,247,255,5)]"
          />
          <img
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/c/c-plain.svg"
            alt="C logo"
            title="C"
            className="w-16 h-16 mx-auto p-2 hover:drop-shadow-[0_4px_6px_rgba(18,247,255,5)]"
          />
          <img
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/cplusplus/cplusplus-original.svg"
            alt="C++ logo"
            title="C++"
            className="w-16 h-16 mx-auto p-2 hover:drop-shadow-[0_4px_6px_rgba(18,247,255,5)]"
          />
          <img
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/numpy/numpy-original-wordmark.svg"
            alt="Numpy logo"
            title="Numpy"
            className="w-16 h-16 mx-auto p-2 hover:drop-shadow-[0_4px_6px_rgba(18,247,255,5)]"
          />
          <img
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/pandas/pandas-original-wordmark.svg"
            alt="Pandas logo"
            title="Pandas"
            className="w-16 h-16 mx-auto p-2 hover:drop-shadow-[0_4px_6px_rgba(18,247,255,5)]"
          />
          <img
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/git/git-plain-wordmark.svg"
            alt="Git logo"
            title="Git"
            className="w-16 h-16 mx-auto p-2 hover:drop-shadow-[0_4px_6px_rgba(18,247,255,5)]"
          />
          <img
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/github/github-original-wordmark.svg"
            alt="GitHub logo"
            title="GitHub"
            className="w-16 h-16 mx-auto p-2 hover:drop-shadow-[0_4px_6px_rgba(18,247,255,5)]"
          />
        </div>
      </section>
    </div>
  );
};

export default Skills;
