import "./App.css";
import Header from "./Components/Header";
import Home from "./Components/Home";
import About from "./Components/About";
import Projects from "./Components/Projects";
import Skills from "./Components/Skills";
import Footer from "./Components/Footer";
import { Element } from "react-scroll";

function App() {
  return (
    <div className="App">
      <div className="min-h-screen font-sans text-gray-200 font-poppins">
        <Header />  
        <main>
          <Element name="home">
            <Home />
          </Element>
          <Element name="about">
            <About />
          </Element>
          <Element name="projects">
            <Projects />
          </Element>
          <Element name="skills">
            <Skills />
          </Element>
          <Footer />
        </main>
      </div>
    </div>
  );
}

export default App;
